import React, { useEffect, useMemo, useState} from 'react';
import { useDispatch } from 'react-redux'
import {getClient, getNetworkClient} from "./chainClient";
import { assets as cryptoassets , chains, unitToCurrency } from '@liquality/cryptoassets'
import {useWeb3React} from "@web3-react/core";
import useInterval from "../hooks/useInterval";
import config  from "./config";
import {getAgentClient} from './agentClient'
import {setMarkets } from "../state/crosschainswap/actions";

const CrossChainClientContext = React.createContext(null);
interface Props {
    children: any;
}
export const  CrossChainClientProvider = (props: Props) => {


    const dispatch = useDispatch()

    const [activeWalletDetails, setActiveWalletDetails] = useState({
        allAddresses: [],
        networkBalance: 0,
        balance: 0,

        unitNetworkBalance: 0,
        unitBalance: 0
    });
    const { active, library } = useWeb3React()



    const getMarketData = async () => {
        const marketInfo = await getAgentClient(config.agents[0]).getMarketInfo();
        const formattedMarkets = marketInfo.map(m => {

            const convertedMin: string = unitToCurrency(cryptoassets[m.from], m.min).toString()
            const convertedMax: string = unitToCurrency(cryptoassets[m.from], m.max).toString()
            return {
                ...m,
                min: convertedMin,
                max: convertedMax,
                from: m.from,
                to: m.to,
                rate:  m.rate,
                agent: config.agents[0]
            }
        })
        dispatch(setMarkets({markets: formattedMarkets}))
    }
    useEffect(()=>{
        getMarketData()
    }, [])

    useInterval(getMarketData, 1000 * 60 * 2);


    useEffect(()=>{
        if (active && library?.provider?.isCoinWallet) {
            getWalletDetailsAfterConnection('BTC');
        } else {
            setActiveWalletDetails({
                allAddresses: [],
                networkBalance: 0,
                balance: 0,

                unitNetworkBalance: 0,
                unitBalance: 0
            })
        }
    }, [active, library?.provider?.isCoinWallet])

    const getWalletDetailsAfterConnection = async (currencyCode) => {
        const wallet = 'coin';

        const currency = cryptoassets[currencyCode]

        const chain = chains[currency.chain]
        const client = getClient(currencyCode, wallet)

        const networkClient = getNetworkClient(currencyCode, wallet)

        const addressesPerCall = 100
        const unusedAddress = await client.wallet.getUnusedAddress()

        let allAddresses = await client.wallet.getUsedAddresses(addressesPerCall)

        allAddresses = [ ...new Set([ unusedAddress, ...allAddresses ].map(a => a.address)) ]
        allAddresses = allAddresses.map(address => chain.formatAddress(address))

        const balance = await client.chain.getBalance(allAddresses)
        const networkBalance = networkClient === client ? balance : await networkClient.chain.getBalance(allAddresses)

        setActiveWalletDetails({
            allAddresses,
            networkBalance: unitToCurrency(currency, networkBalance),
            balance: unitToCurrency(currency, balance),

            unitNetworkBalance: networkBalance.toString(),
            unitBalance:balance.toString(),
        })
    }

    const btcClient = useMemo(()=>{

        if (active && library?.provider?.isCoinWallet) {
            return getClient('BTC', 'coin')
        }
        return undefined

    }, [active, library?.provider?.isCoinWallet])

    const ethClient = useMemo(()=>{

        if (active && library?.provider?.isCoinWallet) {
            return getClient('ETH', 'coin')
        }
        return undefined
    }, [active, library?.provider?.isCoinWallet])

    const erc20Client = useMemo(()=>{

        if (active && library?.provider?.isCoinWallet) {
            return getClient('DAI', 'coin')
        }
        return undefined
    }, [active, library?.provider?.isCoinWallet])

    return (
        <CrossChainClientContext.Provider
            value={{
                btcClient,
                ethClient,
                erc20Client,
                activeWalletDetails
            }}
        >
            {props.children}
        </CrossChainClientContext.Provider>
    );
}
export const useCrossChainClient = () => React.useContext(CrossChainClientContext)
