import { HttpLink, from, split } from "@apollo/client";

import { RetryLink } from "@apollo/client/link/retry";

// export const uniswap = from([
//     new RetryLink(),
//     new HttpLink({
//         uri: "https://api.thegraph.com/subgraphs/name/osinakayah/v2uniswap",
//         // shouldBatch: true,
//     }),
// ]);

export const bar = from([
    new RetryLink(),
    new HttpLink({
        uri: "https://api.thegraph.com/subgraphs/name/hodlarchitect/bsc-tnet-bar",
        // shouldBatch: true,
    }),
]);

export const masterchef = from([
    new RetryLink(),
    new HttpLink({
        uri: "https://api.thegraph.com/subgraphs/name/hodlarchitect/bsc-tnet-masterchef",
        // shouldBatch: true,
    }),
]);
export const exchange = from([
    new RetryLink(),
    new HttpLink({
        uri: "https://api.thegraph.com/subgraphs/name/hodlarchitect/bsc-tnet-exchange",
        // shouldBatch: true,
    }),
]);

export const blocklytics = from([
    new RetryLink(),
    new HttpLink({
        uri: "https://thegraph.com/explorer/subgraph/osinakayah/bsc-tnet-blocks"
        // shouldBatch: true,
    }),
]);

export const lockup = from([
    new RetryLink(),
    new HttpLink({
        uri: "https://api.thegraph.com/subgraphs/name/hodlarchitect/lockup",
        // shouldBatch: true,
    }),
]);

export default split(
    (operation) => {
        return operation.getContext().clientName === "blocklytics";
    },
    blocklytics,
    split(
        (operation) => {
            return operation.getContext().clientName === "masterchef";
        },
        masterchef,
        split(
            (operation) => {
                return operation.getContext().clientName === "bar";
            },
            bar,
            split(
                (operation) => {
                    return operation.getContext().clientName === "lockup";
                },
                lockup,
                exchange
            )
        )
    )
);
