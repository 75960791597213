import React, { useCallback, useEffect, useMemo } from 'react'
import { useMoralis } from "react-moralis";
import { fortmatic, injected, portis, walletconnect, walletlink } from '../../connectors'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'

import { NATIVE } from '@hodlvalley/sdk'
import { t } from '@lingui/macro'

import { AbstractConnector } from '@web3-react/abstract-connector'
import Image from 'next/image'
// import Loader from '../Loader'
import { NetworkContextName } from '../../constants'
import { TransactionDetails } from '../../state/transactions/reducer'
import Web3Connect from '../Web3Connect'
// import { shortenAddress } from '../../functions/format'
import styled from 'styled-components'
import useENSName from '../../hooks/useENSName'
// import { useWalletModalToggle } from '../../state/application/hooks'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useETHBalances } from '../../state/wallet/hooks'
import { useLingui } from '@lingui/react'
import { showToast, ToastTypes } from 'hooks/useToast'

const IconWrapper = styled.div<{ size?: number }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
	return b.addedTime - a.addedTime
}

const SOCK = (
	<span role="img" aria-label="has socks emoji" style={{ marginTop: -4, marginBottom: -4 }}>
    🧦
  </span>
)

// eslint-disable-next-line react/prop-types
function StatusIcon({ connector }: { connector: AbstractConnector }) {
	if (connector === injected) {
		return <Image src="/chef.svg" alt="Injected (MetaMask etc...)" width={20} height={20}/>
		// return <Identicon />
	} else if (connector === walletconnect) {
		return (
			<IconWrapper size={16}>
				<Image src="/images/wallets/wallet-connect.png" alt={'Wallet Connect'} width="16px" height="16px"/>
			</IconWrapper>
		)
	} else if (connector.constructor.name === 'LatticeConnector') {
		return (
			<IconWrapper size={16}>
				<Image src="/images/wallets/lattice.png" alt={'Lattice'} width="16px" height="16px"/>
			</IconWrapper>
		)
	} else if (connector === walletlink) {
		return (
			<IconWrapper size={16}>
				<Image src="/images/wallets/coinbase.svg" alt={'Coinbase Wallet'} width="16px" height="16px"/>
			</IconWrapper>
		)
	} else if (connector === fortmatic) {
		return (
			<IconWrapper size={16}>
				<Image src="/images/wallets/fortmatic.png" alt={'Fortmatic'} width="16px" height="16px"/>
			</IconWrapper>
		)
	} else if (connector === portis) {
		return (
			<IconWrapper size={16}>
				<Image src="/images/wallets/portis.png" alt={'Portis'} width="16px" height="16px"/>
			</IconWrapper>
		)
	}
	return null
}

function Web3StatusInner() {
	const { account, chainId, deactivate, library, error } = useWeb3React()
	const { i18n } = useLingui()
	const { authenticate: moralisAuthenticate, isAuthenticated: moralisIsAuthenticated, logout: moralisLogout} = useMoralis();
	const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']


	const { ENSName } = useENSName(account ?? undefined)

	const allTransactions = useAllTransactions()

	const sortedRecentTransactions = useMemo(() => {
		const txs = Object.values(allTransactions)
		return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
	}, [allTransactions])

	const pending = sortedRecentTransactions
		.filter(tx => {
			return tx.receipt ? false : (tx.archer && tx.archer.deadline * 1000 - Date.now() < 0) ? false : true
		})
		.map((tx) => tx.hash)

	useEffect(() => {
		if (account && !moralisIsAuthenticated) {
			moralisAuthenticate({ signingMessage: "Hodlverse Authentication" });
		}

		if (!account && moralisIsAuthenticated) {
			moralisLogout();
		}
	}, [account, moralisIsAuthenticated, moralisAuthenticate, moralisLogout])

	useEffect(() => {
		if (error && error instanceof UnsupportedChainIdError) {
			showToast("You are on the wrong network", {
				type: ToastTypes.error,
				timeout: 5000
			})
		}
	}, [error])


	return account || error ? (
		<>
			<div
				id="web3-status-connected"
				className="flex items-center pr-3 py-2 text-sm rounded-lg bg-dark-1000 text-secondary xs:w-full xs:pt-14px"
			>
				<div className={`flex xs:flex-col`}>
					<p className={`text-white font-bold opacity-80 text-sm mr-15px xs:text-xl`}>{i18n._(t`MY WALLET`)}</p>
					{!error &&
						<p className={'text-white font-bold text-sm xs:text-xl xs:mt-6px'} id={'menu-wallet-balance-id'}>
							{userEthBalance?.toSignificant(4)} {NATIVE[chainId].symbol}
						</p>
					}
				</div>
			</div>
			<div className={'border-l-4 border-red-500 h-full flex cursor-pointer pl-11px -mr-10px xs:border-none'}
					 id={'menu-logout-wrapper-id'}>
				<Image onClick={() => {
					localStorage.setItem('ACTIVE_WALLET', library?.provider?.isCoinWallet ? 'coin' : 'injected')
					deactivate()
				}} src="/icons/logout.svg" alt="Logout" width="19" height="18"/>
			</div>
		</>
	) : (
		<Web3Connect/>
	)
}

export default function Web3Status() {
	const { active } = useWeb3React()
	const contextNetwork = useWeb3React(NetworkContextName)

	if (!contextNetwork.active && !active) {
		return null
	}

	return !contextNetwork.active && !active ? null : <Web3StatusInner/>
}
