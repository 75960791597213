import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import { DateTime } from 'luxon'
import { Button, Modal, TokenDropdown, TokenDropdownTypes, Dots, Tooltip } from 'components'
import { Sizes } from 'types/Sizes'

import useSWR from 'swr'
import { request } from 'graphql-request'
import {ChainId, CurrencyAmount, JSBI, STAKING_ADDRESS, ZERO} from '@hodlvalley/sdk'

import { useTokenBalance } from 'state/wallet/hooks'
import { MONEY, XMONEY } from '../../constants'
import { tryParseAmount } from 'functions'
import { ApprovalState, useApproveCallback, useSushiBar, useActiveWeb3React } from 'hooks'
import useReserveContract from 'features/stake/useReserve'

import { TransactionType } from 'modals/TransactionConfirmationModal'

import { Confirmation } from 'modals'
import { responsiveSizes } from 'constants/sizes'
import { useWindowSize } from 'hooks/useWindowSize'

import styles from './addRemoveStake.module.scss'
import {GRAPH_HOST} from "services/graph/constants";
import {MASTERCHEF_V1} from "services/graph";

const INPUT_CHAR_LIMIT = 18
const sendTx = async (txFunc: () => Promise<any>): Promise<boolean> => {
	let success = true
	try {
		const ret = await txFunc()
		const { hash } = ret
		if (ret?.error) {
			return false
		}
		return hash
	} catch (e) {
		console.error(e)
		success = false
	}
	return success
}

interface AddRemoveStakeModalProps {
	isRemove: boolean;
}

const AddRemoveStakeModal: React.FC<AddRemoveStakeModalProps> = ({ isRemove }) => {
	const activeTab = isRemove ? 1 : 0
	const { width: screenWidth } = useWindowSize()
	const router = useRouter()
	const { account, chainId } = useActiveWeb3React()

	const SUBGRAPH = {
		[ChainId.ROPSTEN]: `${GRAPH_HOST[chainId]}/subgraphs/name/hodlarchitect/bar`,
		[ChainId.BSC_TESTNET]: `${GRAPH_HOST[chainId]}/subgraphs/name/hodlarchitect/bsc-tnet-bar`
	}

	const { i18n } = useLingui()
	const moneyBalance = useTokenBalance(account ?? undefined, MONEY[chainId])
	const xMoneyBalance = useTokenBalance(account ?? undefined, XMONEY[chainId])
	const { enter, leave } = useSushiBar()

	const { moneyBalance: moneyReserveBalance } = useReserveContract()

	const [hash, setHash] = useState('')
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
	const [pendingTx, setPendingTx] = useState(false)
	const [input, setInput] = useState<string>('')
	const [stakePercent, setStakePercent] = useState('0')
	const [apr, setApr] = useState(0)

	const walletConnected = useMemo(() => !!account, [account])
	const { data } = useSWR(`{bar(id: "${XMONEY[chainId].address.toLowerCase()}") {
			ratio, 
			totalSupply
		}
		user(id: "${account?.toLowerCase()}") {
    		updatedAt
  		}
	}`,
		(query) =>
			request(SUBGRAPH[chainId], query)
	)

	const dateAdded = useMemo<string>(()=>{

		if (data?.user) {
			return  DateTime.fromSeconds(Number(data.user.updatedAt)).toFormat('LL/d/yyyy')
		}
		return "N/A"
	}, [data?.user])
	const balance = activeTab === 0 ? moneyBalance : xMoneyBalance

	const parsedAmount = useMemo(() => tryParseAmount(input, balance?.currency), [balance, input])

	const [approvalState, approve] = useApproveCallback(parsedAmount, STAKING_ADDRESS[chainId])

	const insufficientFunds = (balance && balance.equalTo(ZERO)) || parsedAmount?.greaterThan(balance)

	const inputError = insufficientFunds

	const buttonDisabled = (parseFloat(input) <= 0) || pendingTx || (parsedAmount && parsedAmount.equalTo(ZERO))

	// TODO: DROP AND USE SWR HOOKS INSTEAD
	useEffect(() => {
		const totalReserve = moneyReserveBalance ? parseFloat(moneyReserveBalance.toSignificant(6)) : 0

		const stakingRewards = totalReserve * 0.3
		const totalStaked = data?.bar?.totalSupply ? data?.bar?.totalSupply : 1
		setApr((stakingRewards / totalStaked) * 100)
	}, [data?.bar?.totalSupply, moneyReserveBalance])

	useEffect(() => {
		if (balance?.currency) {
			const correctNum = Number(stakePercent)

			const unstakedBalance = moneyBalance ? moneyBalance : CurrencyAmount.fromRawAmount(balance?.currency, JSBI.BigInt('0'))
			const stakedBalance = xMoneyBalance ? xMoneyBalance : CurrencyAmount.fromRawAmount(balance?.currency, JSBI.BigInt('0'))

			const activeAmount = isRemove ? stakedBalance : unstakedBalance

			const num = CurrencyAmount.fromRawAmount(balance?.currency, JSBI.BigInt(`${correctNum}`))
			const full = CurrencyAmount.fromRawAmount(balance?.currency, JSBI.BigInt(`${100}`))
			const percent = num.divide(full)

			const newMoney = (activeAmount.multiply(percent)).toSignificant(6)
			if (newMoney.length <= INPUT_CHAR_LIMIT) {
				setInput(newMoney)
			}
		}
	}, [stakePercent, moneyBalance, xMoneyBalance, isRemove])

	const handleClickButton = async () => {
		if (buttonDisabled) return

		if (!walletConnected) {
			router.push('/connect')
			return
		} else {
			setPendingTx(true)

			if (activeTab === 0) {
				if (approvalState === ApprovalState.NOT_APPROVED) {
					const success = await sendTx(() => approve())
					if (!success) {
						setPendingTx(false)
						// setModalOpen(true)
						return
					}
				}
				const success = await sendTx(() => enter(parsedAmount))
				if (!success) {
					setPendingTx(false)
					// setModalOpen(true)
					return
				} else {
					setHash(String(success))
				}
			} else if (activeTab === 1) {
				const success = await sendTx(() => leave(parsedAmount))
				if (!success) {
					setPendingTx(false)
					// setModalOpen(true)
					return
				} else {
					setHash(String(success))
				}
			}

			setStakePercent('0')
			setPendingTx(false)
		}
	}

	const handleChangePercent = useCallback((val: string) => {
		const correctVal = val.replace(/[^\d.-]/g, '')
		const num = isNaN(Number(correctVal)) ? 0 : Number(correctVal)
		const correctNum = num < 0 ? 0 : num > 100 ? 100 : num
		setStakePercent(`${correctNum}`)
	}, [])

	const dropdownForm = useMemo(() => (
		<div className={'xs:relative'}>
			<div className={'-mt-9px xs:mt-16px'}>
				<TokenDropdown
					disabled={false}
					operation={isRemove ?  `${i18n._(t`Unstake`)}` : `${i18n._(t`Stake`)}`}
					amount={stakePercent}
					onChangeAmount={handleChangePercent}
					allowEditAmount={true}
					size={Sizes.SMALL}
					type={TokenDropdownTypes.INPUT_STEPPER}
					suffix={'%'}
					autosizeInput={screenWidth < responsiveSizes.xs}
					readonly={false}/>
			</div>
			<div className={'flex justify-end mt-7px mr-10px xs:absolute xs:right-85px xs:top-10px xs:z-20 uppercase'}>
				<button id={'stage-dropdown-max-btn-id'} onClick={() => {
					handleChangePercent('100')
				}} className={`${styles.yellowBtn}`}>{i18n._(t`Max`)}
				</button>
			</div>
		</div>
	), [stakePercent, handleChangePercent, isRemove, screenWidth])

	return (
		<>
			<Modal isOpen={!isConfirmationModalOpen}
						 title={{ firstLine: isRemove ? `${i18n._(t`Remove`)}` : `${i18n._(t`Add`)}`, secondLine: `${i18n._(t`Stake`)}` }}
						 description={`${i18n._(t`The property for the staking of the MONEY token in the HODLVERSE network`)}.`}
						 mainIconName={'Mine'}>
				<div className={`h-full relative xs:h-500px`}>
					<div className={`h-full flex flex-col`}>
						<div className={styles.assetForm}>
							<div className={'flex xs:px-27px xs:w-full'}>
								<div className={styles.leftColumn}>
									<div className={styles.infoRow}>
										<p className={styles.title}>{i18n._(t`Staking APR`)}</p>
										<div className={'w-1/2 truncate'}>
											<Tooltip message={`${apr}%`}>
												<p className={`${styles.value} w-full truncate`} id={'stake-staking-apr-val-id'}>{apr}%</p>
											</Tooltip>
										</div>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>{i18n._(t`Balance`)}</p>
										<p className={styles.value} id={'stake-balance-val-id'}>{xMoneyBalance ? xMoneyBalance.toSignificant(4) : '-'}</p>
									</div>
									<div className={'xs:hidden'}>
										<div className={styles.infoRow}>
											<p className={styles.title}>{i18n._(t`Unstaked`)}</p>
											<p className={styles.value} id={'stake-unstaked-val-id'}>{moneyBalance ? moneyBalance.toSignificant(4) : '-'}</p>
										</div>
									</div>
									<div className={styles.infoRow}>
									  <p className={styles.title}>DATE ADDED</p>
									  <p className={styles.value} id={'stake-date-added-id'}>{dateAdded}</p>
									</div>
								</div>
								<div className={styles.rightColumn}>
									<div className={'hidden xs:block'}>
										<div className={styles.infoRow}>
											<p className={styles.title}>{i18n._(t`Date added`)}</p>
											<p className={styles.value} id={'stake-date-added-mobile-id'}>12/1/2021</p>
										</div>
									</div>
									<div className={'hidden xs:block'}>
										<div className={styles.infoRow}>
											<p className={styles.title}>{i18n._(t`Unstaked`)}</p>
											<p className={styles.value} id={'stake-unstaked-val-mobile-id'}>{moneyBalance ? moneyBalance.toSignificant(4) : '-'}</p>
										</div>
									</div>
									<div className={'xs:hidden'}>
										{dropdownForm}
									</div>
								</div>
							</div>
							<div className={'hidden xs:block'}>
								{dropdownForm}
							</div>
						</div>
						<div className={`w-full ${styles.receiveForm}`}>
							<div className={`${styles.calcColumn} items-center w-full justify-center`}>
								<p
									id={'money-val-id'}
									className={'font-medium text-gray-400 text-40px xs:text-26px tracking-normal leading-8'}>
									{parsedAmount ? parsedAmount.toSignificant(4, { groupSeparator: ',' }) : input}
								</p>
								<p
									className={'font-medium text-gray-400 xs:text-gray-620 text-xl xs:text-xs tracking-normal leading-10 mt-6px xs:mt-1px uppercase'}>{i18n._(t`Money`)}</p>
							</div>
							<div className={'flex justify-end xs:justify-center items-center mt-38px xs:mt-[20px]'}>
								{(approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING) && activeTab === 0 ? (
									<Button
										id={'stake-cta-btn-id'}
										disabled={approvalState === ApprovalState.PENDING}
										onClick={approve}
									>
										{approvalState === ApprovalState.PENDING ? (
											<Dots>Approving</Dots>
										) : (`Approve`)}
									</Button>
								) : (
									<Button
										id={'stake-cta-btn-id'}
										onClick={handleClickButton}
										disabled={buttonDisabled || inputError}
									>
										{!walletConnected
											? `${i18n._(t`Connect wallet`)}`
											: parseFloat(input) <= 0
												? `${i18n._(t`Enter an amount`)}`
												: insufficientFunds
													? `Insufficient Balance`
													: activeTab === 0
														? `${i18n._(t`Confirm staking`)}`
														: `Confirm Withdrawal`}
									</Button>
								)}

							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Confirmation type={TransactionType.ADD_STAKE}
										isOpen={isConfirmationModalOpen}
										onDismiss={() => setIsConfirmationModalOpen(false)}
										submitted={!!hash}
										chainId={chainId}
										hash={hash}
										amount={parsedAmount
											? parsedAmount.toSignificant(4, { groupSeparator: ',' })
											: input}/>
		</>
	)
}

export default AddRemoveStakeModal
