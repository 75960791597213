import React, { ReactNode, useEffect, useMemo, useRef } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import useModals from 'state/modals/hooks'

import { Sizes } from 'types/Sizes'

import { BackButton, FormDetails, FormDetailsConfigModel } from 'components'

import { useWindowSize } from 'hooks/useWindowSize'

import { TOOLTIP, TRANSACTION_NOTIFICATION_TOAST } from 'constants/popups'

import styles from './modal.module.scss'
import { responsiveSizes } from 'constants/sizes'

interface ModalProps {
	isOpen: boolean;
	title: {
		firstLine: string;
		secondLine?: string;
		secondLineSize?: Sizes;
	},
	description?: string;
	children: ReactNode;
	size?: Sizes;
	formDetailsConfig?: FormDetailsConfigModel;
	headBlock?: ReactNode;
	isConfirmation?: boolean;
	firstLineClassname?: string;
	mainIconName?: string;
	showFormDetailsModal?: boolean;
	onManualChangeVisFormDetails?: (show: boolean) => void;
	customBackAction?: () => void;
	addTopSpace?: boolean;
	addMobileTopSpace?: boolean;
}

const Modal: React.FC<ModalProps> = ({
																			 isOpen,
																			 title,
																			 description = '',
																			 children,
																			 size = Sizes.NORMAL,
																			 formDetailsConfig,
																			 headBlock,
																			 isConfirmation = false,
																			 mainIconName = 'Factory',
																			 firstLineClassname = '',
																			 showFormDetailsModal= false,
																			 onManualChangeVisFormDetails,
																			 customBackAction,
																			 addTopSpace = false,
																			 addMobileTopSpace = false
																		 }) => {
	const { width: screenWidth } = useWindowSize();

	const router = useRouter()
	const { closeModals } = useModals()

	const wrapperRef = useRef(null)

	useEffect(() => {
		const checkIfToast = (el: HTMLElement) => (
			el
				? el.classList.contains(TRANSACTION_NOTIFICATION_TOAST) || el.classList.contains(TOOLTIP)
				? true
				: el.parentElement
					? checkIfToast(el.parentElement)
					: false
				: false
		)

		const checkIfAnotherModal = (el: HTMLElement) => (
			el
				? el.id && (el.id === "modal-confirmation-id" || el.id === "modal-id")
				? true
				: el.parentElement
					? checkIfAnotherModal(el.parentElement)
					: false
				: false
		)

		const handleClickOutside = (event) => {
			if (isOpen && wrapperRef.current && !wrapperRef.current.contains(event.target) && !checkIfToast(event.target) && !checkIfAnotherModal(event.target) && screenWidth > responsiveSizes.xs) {
				closeModals()
				router.push('/') // Commented out so that swaps, pool etc will work. If enabled, performing all these actions redirect the user to home screen
			}
		}

		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [wrapperRef, closeModals, isOpen, router, screenWidth])

	const FromDetails = useMemo(() => (
		<>
			{formDetailsConfig &&
				<div className={`absolute left-0 flex justify-center w-full top-[calc(100%+10px)] xs:top-auto xs:bottom-154px xs:h-fit xs:z-20`} id={'modal-form-details-id'}>
					<FormDetails config={formDetailsConfig} showFormDetailsModal={showFormDetailsModal} onManualChangeVisFormDetails={onManualChangeVisFormDetails}/>
				</div>
			}
		</>
	), [formDetailsConfig, showFormDetailsModal, onManualChangeVisFormDetails])

	return (
		<div
			className={`w-screen h-screen top-0 xs:top-68px left-0 flex justify-center items-center blurWrapper z-40 
			xs:py-23px xs:w-full xs:flex-col xs:justify-end xs:h-[calc(100%-68px)] xs:overflow-y-scroll xs:items-end ${isOpen ? 'fixed xs:inline-grid' : 'hidden'}`}>
			{isConfirmation ? (
				<div
					id={'modal-confirmation-id'}
					className={`relative bg-white bg-opacity-50 border-10 xs:border-4 border-grey-100 flex flex-col rounded-80 xs:rounded-40 
					${size === Sizes.EXTRA_SMALL ? 'w-528px xs:w-87.5% xs:mx-auto' : ''}`}
					ref={wrapperRef}>
					<div
						className={`relative border-b-10 xs:border-b-4 border-white rounded-tl-70 rounded-tr-70 xs:rounded-tl-30 xs:rounded-tr-30 
						bg-gray-200 py-52px xs:pt-27px xs:pb-20px px-63px xs:px-30px xs:w-full 
						${size === Sizes.EXTRA_SMALL ? `pb-0 w-full px-40px` : 'w-43.5%'}`}>
						<div className={'relative z-10'}>
							<div className={`relative h-150px xs:h-74px`}>
								<div className={`absolute pointer-events-none w-600px xs:w-310px -bottom-120px xs:-bottom-66px -left-60px xs:-left-21px`}>
									<Image src={'/images/walletConfirmation.webp'} alt={'Wallet'}
												 width={screenWidth < responsiveSizes.xs ? 310 : 592}
												 height={screenWidth < responsiveSizes.xs ? 310 : 592}/>
								</div>
							</div>
							<p
								className={`uppercase font-medium text-gray-400 tracking-normal text-center text-60px xs:text-28px 
								mt-18px xs:mt-21px leading-14 xs:leading-8 ${firstLineClassname}`}>
								{title.firstLine}
							</p>
							{headBlock}
						</div>
					</div>
					<div
						className={`rounded-tr-70 rounded-br-70 xs:w-full ${size === Sizes.EXTRA_SMALL ? 'w-full' : 'w-56.5%'}`}>
						{children}
					</div>
					{FromDetails}
				</div>
			) : (
				<div
					id={'modal-id'}
					className={`relative border-10 border-grey-100 flex rounded-80 bg-gray-200 bg-opacity-80 w-1210px h-600px form xs:mx-auto xs:w-85.5%
            ${size === Sizes.SMALL ? 'w-1080px' : size === Sizes.MEDIUM ? 'w-1088px' : size === Sizes.SMALLER ? 'w-1038px' : ''}
            xs:flex-col xs:h-fit xs:bg-transparent xs:border-0 xs:form-xs`}
						 ref={wrapperRef}>
					<div className={`relative border-r-10 border-white rounded-tl-70 rounded-bl-70 py-52px pl-63px w-43.5% 
													${size === Sizes.SMALL ? 'w-51%' : size === Sizes.MEDIUM ? 'w-48.5%' : size === Sizes.SMALLER ? 'w-50.9%' : ''}
													xs:w-full xs:border-0 xs:px-10px xs:py-10px xs:mb-13px`}>
						<div className={'relative z-10'}>
							<div className={'xs:flex xs:items-center xs:justify-center'}>
								<p
									className={`uppercase font-medium text-gray-400 tracking-normal text-45px leading-50px xs:text-center indent-1
														xs:text-32px xs:text-white xs:leading-7`}>
									{title.firstLine}
								</p>
								<p className={`uppercase font-medium text-gray-400 tracking-normal mt-2px leading-90px xs:text-32px xs:text-white
															w-full overflow-y-scroll xs:overflow-y-visible xs:w-fit
																xs:pl-10px xs:leading-5 xs:mt-0
								${title?.secondLineSize === Sizes.SMALL ? 'text-80px' : 'text-90px'}`}>
									{title.secondLine}
								</p>
							</div>
							<p className={`font-medium text-gray-400 opacity-60 text-sm tracking-normal 
															xs:text-xs xs:text-white xs:text-opacity-80 xs:text-center xs:mt-11px pr-63px xs:px-0`}>
								{description}
							</p>
						</div>
						<div className={'absolute top-0 left-0 w-full h-full overflow-hidden xs:hidden'}>
							<div className={styles.mainIconWrapper}>
								<Image src={`/images/modals/${mainIconName}.webp`} alt={'Icon'} layout='fill' objectFit='cover'/>
								{/*<Image src={`/images/modals/${mainIconName}.png`} alt={'Icon'} width={710} height={510} layout="responsive"/>*/}
							</div>
						</div>
						<div className={`${styles.cloud1} xs:hidden`}>
							<Image src={'/images/cloud.png'} alt={'Cloud'} layout='fill' objectFit='contain'/>
						</div>
						<div className={`${styles.cloud2} xs:hidden`}>
							<Image src={'/images/cloud.png'} alt={'Cloud'} layout='fill' objectFit='contain'/>
						</div>
						<div className={`${styles.cloud3} xs:hidden`}>
							<Image src={'/images/cloud.png'} alt={'Cloud'} layout='fill' objectFit='contain'/>
						</div>
						<div className={`${styles.cloud4} xs:hidden`}>
							<Image src={'/images/cloud.png'} alt={'Cloud'} layout='fill' objectFit='contain'/>
						</div>
					</div>
					<div className={`rounded-tr-70 rounded-br-70 w-56.5% xs:w-full xs:form-right-column-xs relative ${addTopSpace && 'pt-[50px]'}
													${addMobileTopSpace && 'xs:pt-[50px]'}
													 xs:border-grey-100 xs:rounded-40 xs:bg-gray-200 xs:bg-opacity-80 xs:border-10
					${size === Sizes.SMALL ? 'w-49%' : size === Sizes.MEDIUM ? 'w-51.5%' : size === Sizes.SMALLER ? 'w-49.1%' : ''}`}>
						<div className={"absolute top-[23px] xs:top-[13px] left-[72px] xs:left-[42px] z-10"}>
							<BackButton size={Sizes.EXTRA_SMALL} onClick={() => customBackAction ? customBackAction() : router.back()} />
						</div>
						{children}
					</div>
					{FromDetails}
				</div>
			)}
		</div>
	)
}

export default Modal
