import { toast } from 'react-toastify';
import { Toast } from "../components"

const defaultTimeout = 3000
const currentToastList = []

export enum ToastTypes {
  error,
  success
}

export interface toastOptions {
  type?: ToastTypes,
  timeout?: number;
  autosize?: boolean;
}

export const showToast = (text = '', options?: toastOptions) => {
  let isShow = true
  if (currentToastList.some(x => x.text === text)) {
    isShow = false
  }

  if (isShow) {
    const id = Math.random().toString(36).substr(2, 9)
    currentToastList.push({
      text,
      id
    })

    setTimeout(() => {
      const index = currentToastList.findIndex(x => x.id === id)
      if (index > -1) {
        currentToastList.splice(index, 1)
      }
    }, defaultTimeout)

    const modal = document.getElementById('modal-id');
    let topOffset = 0;
    if (modal) {
      const sizes = modal.getBoundingClientRect();
      topOffset = (sizes?.top || 0) - 51;
    }

    toast.info(<Toast text={text} type={options?.type || ToastTypes.error} autosize={options?.autosize || false} />, {
    position: "top-center",
      autoClose: options?.timeout || defaultTimeout,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        top: topOffset < 100 ? 100 : topOffset
      }
  });
}
};
