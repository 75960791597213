import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useMoralis, useMoralisQuery, useNewMoralisObject } from 'react-moralis'

import { Menu, Popups, LanguageNetworkSwitcher } from 'components'

import styles from './default.module.scss'
// import useMultiplayer from 'state/multiplayer/hooks'

interface LayoutProps {
	blurBg?: boolean;
	children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, blurBg }) => {
	const router = useRouter()
	const isUnityReady = false;
	// const { startMultiplayer, setConnected, setUnityReady, isUnityReady } = useMultiplayer()
	const { fetch, data: unityStoreData, error: fetchMoralisDataError } = useMoralisQuery("UnityStore");
	const { save, error: updateMoralisStoreError } = useNewMoralisObject('UnityStore');
	const { isAuthenticated: moralisIsAuthenticated } = useMoralis();

	const [unityLoadProgress, setUnityLoadProgress] = useState(100)

	useEffect(() => {
		const unityLoading = (data: { progress: number, unityInstance: any }) => {
			const {
				progress
			} = data

			const correctProgress = Math.round(progress * 100 || 0)

			if (correctProgress < 100) {
				setUnityLoadProgress(correctProgress)
			} else {
				setTimeout(() => {
					setUnityLoadProgress(100)
				}, 2500)
			}
		}

		const unityLoaderProgress = (e) => {
			unityLoading((e as any).detail)
		}

		const openModal = (e) => {
			const modalName = (e as any).detail.modalName

			if (modalName === 'AIRPORT') {
				// startMultiplayer();
				router.push('/airport')
			}

			if (modalName === 'DATA_CENTER') {
				// not ready yet
			}

			if (modalName === 'COMMUNITY_POOL') {
				router.push('/pool')
			}

			if (modalName === 'SWAP_MEET') {
				router.push('/swapMeet')
			}

			if (modalName === 'USER_HOME') {
				router.push('/home')
			}

			if (modalName === 'COMMUNITY_FARM') {
				router.push('/farm')
			}
		}

		const fetchMoralisUnityStore = () => {
			fetch();
		}

		const updateMoralisUnityStore = (e) => {
			if (e.detail) {
				save(e.detail)
			}
		}

		const onUnityReady = () => {
			// setUnityReady(true);
		}

		window.addEventListener('HODL_VALLEY__UNITY_LOADER_PROGRESS', unityLoaderProgress, false)

		window.addEventListener('HODL_VALLEY__OPEN_MODAL', openModal, false)

		if (moralisIsAuthenticated) {
			window.addEventListener('HODL_VALLEY__FETCH_MORALIS_UNITY_STORE', fetchMoralisUnityStore, false)

			window.addEventListener('HODL_VALLEY__UPDATE_MORALIS_UNITY_STORE', updateMoralisUnityStore, false)
		}

		window.addEventListener('HODL_VALLEY__UNITY_GAME_STARTED', onUnityReady, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__UNITY_LOADER_PROGRESS', unityLoaderProgress);
			window.removeEventListener('HODL_VALLEY__OPEN_MODAL', openModal)
			window.removeEventListener('HODL_VALLEY__FETCH_MORALIS_UNITY_STORE', fetchMoralisUnityStore)
			window.removeEventListener('HODL_VALLEY__UPDATE_MORALIS_UNITY_STORE', updateMoralisUnityStore)
			window.removeEventListener('HODL_VALLEY__UNITY_GAME_STARTED', onUnityReady)
		}
	}, [router, moralisIsAuthenticated])

	useEffect(() => {
		const connectedEvent = () => {
			// setConnected()
		}

		window.addEventListener('HODL_VALLEY__PHOTON_JOINED_LOBBY', connectedEvent, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__PHOTON_JOINED_LOBBY', connectedEvent)
		}
	}, [])

	useEffect(() => {
		if (moralisIsAuthenticated && isUnityReady) {
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_MORALIS_UNITY_STORE_UPDATED', { detail: {} }) as Event)
		}
	}, [unityStoreData, moralisIsAuthenticated, isUnityReady])

	useEffect(() => {
		if (moralisIsAuthenticated && isUnityReady) {
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_MORALIS_UNITY_STORE_FETCH_ERROR', { detail: fetchMoralisDataError }) as Event)
		}
	}, [fetchMoralisDataError, isUnityReady])

	useEffect(() => {
		if (moralisIsAuthenticated && isUnityReady) {
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_MORALIS_UNITY_STORE_UPDATE_ERROR', { detail: updateMoralisStoreError }) as Event)
		}
	}, [updateMoralisStoreError, isUnityReady])

	return (
		<div
			className={`z-0 flex flex-col items-center w-full min-h-screen overflow-x-hidden overflow-y-auto bg-valley-pattern bg-no-repeat bg-cover bg-center relative`}>
			<div
				className={`fixed overflow-hidden w-full h-full z-100 bg-valley-loading-pattern bg-no-repeat bg-cover bg-center flex flex-col items-center justify-center ${unityLoadProgress < 100 ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
				<Image src={'/images/HODLValleyRibbonLogo.webp'} alt="HODLVERSE" width={650} height={536}/>
				<div className={`p-8px ${styles.loader}`}>
					<div className={`${styles.loaderForm} w-full h-full relative flex items-center pl-40px overflow-hidden`}>
						<div className={`${styles.loaderActiveProgress} h-full absolute top-0 left-0`}
								 style={{ width: `calc(${unityLoadProgress}% - 4px)` }}></div>
						<p
							className={'text-2xl uppercase font-medium text-white text-center relative z-10 tracking-normal w-full'}>loading {unityLoadProgress}%...</p>
					</div>
				</div>
			</div>
			<div className={`absolute overflow-visible z-50 xs:hidden ${styles.logoWrapper}`}
					 onClick={() => router.push('/')}>
				<Image src={'/images/HODL-Verse-Logo.png'} alt="HODLVERSE" width={280} height={42}/>
			</div>
			<div className={`absolute z-50 top-33px right-47px xs:fixed xs:top-0 xs:left-0 xs:w-full flex`}>
				<div className={'mr-20px xs:hidden'}>
					<LanguageNetworkSwitcher />
				</div>
				<Menu/>
			</div>
			<Popups/>
			{/*<div className={`fixed w-full h-full top-0 left-0 bg-transparent transition-opacity duration-500 ${unityLoadProgress === 100 ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>*/}
			{/*  <iframe src="/hodlUnityNew/index.html" frameBorder="0" className={'w-full h-full'} />*/}
			{/*</div>*/}
			<main
				className={`w-full min-h-screen max-h-screen overflow-y-scroll pb-50px xs:pb-0 pt-100px xs:pt-68px xs:max-h-max ${blurBg ? 'blurWrapper' : ''}`}>
				{/*<main className={`w-full min-h-screen pb-50px ${blurBg ? 'blurWrapper' : ''} ${styles.contentWrapper}`}>*/}
				{children}
			</main>
		</div>
	)
}

export default Layout
