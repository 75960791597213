import Item from './item'

const LanguageNetworkSwitcher: React.FC = () => (
	<div className={`backdrop-filter-blur-50 h-68px rounded-34 bg-white bg-opacity-80 border-4 border-white flex items-center 
									xs:w-fit xs:h-fit xs:bg-transparent xs:backdrop-filter-none xs:border-0`}>
		<div className={'h-full px-19px border-r-4 border-white flex items-center justify-center pointer xs:border-r-0 xs:px-5px'}>
			<Item network/>
		</div>
		<div className={'h-full px-20px flex items-center justify-center pointer xs:px-5px'}>
			<Item language/>
		</div>
	</div>
)

export default LanguageNetworkSwitcher
