import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useLingui } from '@lingui/react'
import { Tooltip } from 'components/index'
import Input from 'modals/SwapTokens/Input'
import { t } from '@lingui/macro'
import { DEFAULT_DEADLINE_FROM_NOW } from 'constants/index'
import styles from 'modals/SwapTokens/swaptokens.module.scss'
import Toggle from 'modals/SwapTokens/toggle'
import { limitNumber } from 'functions/format'
import { Percent } from '@hodlvalley/sdk'
import {
	useSetUserSlippageTolerance,
	useUserSingleHopOnly,
	useUserSlippageTolerance,
	useUserTransactionTTL
} from 'state/user/hooks'


enum SlippageError {
	InvalidInput = 'InvalidInput',
	RiskyLow = 'RiskyLow',
	RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
	InvalidInput = 'InvalidInput',
}

interface SettingsModalProps {
	toggleSettings: (settings: boolean) => void;
}

// eslint-disable-next-line react/display-name
const SettingsModal = forwardRef<any, SettingsModalProps>(({ toggleSettings }, ref) => {
	const { i18n } = useLingui()

	const setUserSlippageTolerance = useSetUserSlippageTolerance()
	const userSlippageTolerance = useUserSlippageTolerance()
	const [slippageInput, setSlippageInput] = useState('')
	const [deadlineInput, setDeadlineInput] = useState('')
	const [deadline, setDeadline] = useUserTransactionTTL()
	const [, setDeadlineError] = useState<DeadlineError | false>(false)
	const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

	useImperativeHandle(ref, () => ({
		clearValues () {
			// setUserSlippageTolerance('auto')
			// setSlippageInput('')
		}
	}));


	const parseCustomDeadline = useCallback((value: string) => {
		const val = limitNumber(value, 5, 0)

		// populate what the user typed and clear the error
		setDeadlineInput(val)
		setDeadlineError(false)

		if (val.length === 0) {
			setDeadline(DEFAULT_DEADLINE_FROM_NOW)
		} else {
			try {
				if (Number(val) > 180) {
					return parseCustomDeadline("180")
				} else {
					const parsed: number = Math.floor(Number.parseFloat(val) * 60)
					if (!Number.isInteger(parsed) || parsed < 60 || parsed > 180 * 60) {
						setDeadlineError(DeadlineError.InvalidInput)
					} else {
						setDeadline(parsed)
					}
				}

			} catch (error) {
				console.error(error)
				setDeadlineError(DeadlineError.InvalidInput)
			}
		}
	}, [])

	useEffect(() => {
		return () => {
			setSlippageInput('')
		}
	}, [])

	const parseSlippageInput = useCallback((value: string) => {
		const val = limitNumber(value, 2, 8)
		setSlippageInput(val)

		if (val.length === 0) {
			setUserSlippageTolerance('auto')
		} else {
			const parsed = Math.floor(Number.parseFloat(val) * 100)

			if (!Number.isInteger(parsed) || parsed < 0 || parsed > 5000) {
				setUserSlippageTolerance('auto')
				if (val !== '.') {
					console.error(SlippageError.InvalidInput)
				}
			} else {
				setUserSlippageTolerance(new Percent(parsed, 10_000))
			}
		}
	}, [])


	return (
		<div className={`h-full flex flex-col py-22px pl-55px pr-37px xs:py-21px xs:px-12px`} id={'settings-modal-id'}>
			<div className={'mt-70px xs:mt-20px'}>
				<div className={'mb-31px xs:mb-20px'}>
					<Tooltip
						message={<span className={`text-left block tracking-normal ${styles.tooltipLongText}`}>
										{i18n._(t`Set the maximum price tolerance you will accept for your swap. The price tolerance impacts the number of tokens you will receive in your swap.`)}
									</span>}
						customStyle={{ borderRadius: 20 }}>
						<Input
							id={'settings-modal-price-tolerance-input-id'}
							title={`${i18n._(t`Price Tolerance`)}`}
							suffix={'%'}
							placeholder={'0'}
							value={
								slippageInput.length > 0
									? slippageInput
									: userSlippageTolerance === 'auto'
										? '0.5'
										: userSlippageTolerance.toFixed(2)
							}
							onChange={parseSlippageInput}
							manageBlock={<button onClick={() => parseSlippageInput('')}
																	 className={`settings-input__yellow-btn uppercase`}>{i18n._(t`Auto`)}</button>}
						/>
					</Tooltip>
				</div>
				<div className={'mb-31px xs:mb-20px'}>
					<Tooltip
						message={<span className={`text-left block tracking-normal ${styles.tooltipLongText}`}>
										{i18n._(t`Set the maximum time for your swap request to be active and match with other orders on the network.`)}
									</span>}
						customStyle={{ borderRadius: 20 }}>
						<Input
							id={'settings-modal-max-swap-time-input-id'}
							title={`${i18n._(t`Max Swap Time`)}`}
							suffix={`${i18n._(t`Mins`)}`}
							placeholder={(DEFAULT_DEADLINE_FROM_NOW / 60).toString()}
							value={deadlineInput.length > 0
								? deadlineInput
								: deadline === DEFAULT_DEADLINE_FROM_NOW
									? (DEFAULT_DEADLINE_FROM_NOW / 60).toString()
									: (deadline / 60).toString()}
							onChange={(val) => parseCustomDeadline(val)}
							manageBlock={<button className={`settings-input__yellow-btn uppercase`}
																	 onClick={() => parseCustomDeadline('180')}>{i18n._(t`Max`)}</button>}
						/>
					</Tooltip>
				</div>
				<Tooltip
					message={<span className={`text-left block tracking-normal ${styles.tooltipText}`}>
										{i18n._(t`Only allows one route in your swap. This lowers gas costs but may increase time to swap.`)}
									</span>}
					customStyle={{ borderRadius: 20 }}>
					<Input
						id={'settings-modal-disable-multiple-swaps-input-id'}
						title={`${i18n._(t`Disable Multiple Swaps`)}`}
						readonly={true}
						placeholder={'true'}
						value={singleHopOnly ? `${i18n._(t`Yes`)}` : `${i18n._(t`No`)}`}
						manageBlock={<Toggle enabled={singleHopOnly} onChange={() => setSingleHopOnly(!singleHopOnly)} id={'settings-modal-multiple-swaps-toggle-id'}/>}
					/>
				</Tooltip>
			</div>
		</div>
	)
})

export default SettingsModal
