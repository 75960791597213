import React, { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { FixedSizeList } from 'react-window'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Modal, Button, ButtonType } from 'components'

import { Sizes } from 'types/Sizes'

import SuccessIcon from '../../../public/icons/iconSuccess.svg'

import styles from './createViewRoom.module.scss'
import { useWindowSize } from 'hooks/useWindowSize'
import { responsiveSizes } from 'constants/sizes'

export interface RoomPersonModel {
	id: string;
	firstname: string;
	lastname: string;
	joined: boolean;
}

interface RoomInfoProps {
	airportPageLink: string;
}

const RoomInfo: React.FC<RoomInfoProps> = ({ airportPageLink = '' }) => {
	const router = useRouter()
	const { width: screenWidth } = useWindowSize()

	const [personList, setPersonList] = useState<RoomPersonModel[]>([
		{ id: '1', firstname: 'Damon', lastname: 'Nam', joined: false }
	])

	const toggleJoin = useCallback((id: string) => {
		const index = personList.findIndex(x => x.id === id)
		if (index > -1) {
			personList[index].joined = !personList[index].joined
			setPersonList([...personList])
		}
	}, [personList])

	const RenderRow = React.useCallback(
		({ index, style, data }) => {
			const roomPerson = data[index] as RoomPersonModel
			if (roomPerson) {
				return (
					<div key={data[index].id} style={style} className={'px-10px'} id={`room-info-person-${index}-id`} onClick={() => toggleJoin(roomPerson.id)}>
						<div className={`w-full rounded-full flex items-center cursor-pointer transition-opacity text-gray-400 border-white border-3 bg-gray-50 pl-44px pr-9px py-6px xs:px-15px ${styles.listItem}`}>
							<p className={'capitalize text-xl xs:text-base font-medium overflow-ellipsis overflow-hidden w-full whitespace-nowrap'}>{roomPerson.firstname} {roomPerson.lastname}</p>
							<div className={'w-44px h-44px'}>
								<SuccessIcon className={`${roomPerson.joined ? styles.successIconActive : styles.successIcon} w-full h-full`} viewBox="0 0 49 49"/>
							</div>
						</div>
					</div>
				)
			}

			return (
				<div key={data.length} style={style} className={'px-10px'}>
					<div className={'flex items-center justify-center border-dashed border-gray-700 border-2 rounded-52 py-17px xs:py-12px cursor-pointer'}>
						<p className={'text-gray-700 uppercase text-xl xs:text-base font-medium'}>Invite Friends</p>
					</div>
				</div>
			)
		},
		[toggleJoin]
	)

	return (
		<Modal isOpen={true}
					 title={{ firstLine: 'Jimmy\'s', secondLine: 'room' }}
					 description={'The property used to travel to other cities on the network and create or join multiplayer rooms in the HODLVERSE network.'}
					 size={Sizes.SMALLER}
					 mainIconName={'Airport'}>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`transition-opacity h-full w-full absolute top-0 left-0 p-40px opacity-100 xs:py-40px xs:px-20px`}>
					<div className={`h-full flex flex-col`}>
						<div className={`${styles.listWrapper}`}>
							<FixedSizeList
								outerElementType={(props) => <PerfectScrollbar {...props} options={{ suppressScrollX: true }}/>}
								height={308}
								itemData={personList.concat(undefined)}
								itemCount={personList.length + 1}
								itemSize={86}
								width={'100%'}
							>
								{RenderRow}
							</FixedSizeList>
						</div>
						<div className={'mt-30px px-11px flex items-center justify-between'}>
							<Button type={ButtonType.GHOST} size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG}
											onClick={() => router.push(airportPageLink)} id={'view-room-leave-btn-id'}>Leave</Button>
							<Button size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG} id={'view-room-start-btn-id'}>Start</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default RoomInfo
