import React, { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { FixedSizeList } from 'react-window'

import { Modal, ModalMenuItem, ModalMenuInputItem, Button, ButtonType } from 'components'

import { Sizes } from 'types/Sizes'

import styles from './createViewRoom.module.scss'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useWindowSize } from 'hooks/useWindowSize'
import { responsiveSizes } from 'constants/sizes'

export interface RoomModel {
	id: string;
	title: string;
	total: number;
	currentCount: number;
}

const roomList: RoomModel[] = [
	{ id: '1', title: 'Jimmy\'s Room', currentCount: 3, total: 20 },
	{ id: '2', title: 'Empire', currentCount: 11, total: 20 },
	{ id: '3', title: 'Battlefield', currentCount: 14, total: 20 },
	{ id: '4', title: 'The Jungle', currentCount: 4, total: 20 }
]

interface CreateViewRoomProps {
	isCreate?: boolean;
	isView?: boolean;
	airportPageLink: string;
	createRoomPageLink?: string;
}

const CreateViewRoom: React.FC<CreateViewRoomProps> = ({ isCreate = false, isView = false, airportPageLink = '', createRoomPageLink = '' }) => {
	const router = useRouter()
	const { width: screenWidth } = useWindowSize()

	const [roomName, setRoomName] = useState('')

	const selectRoom = useCallback((id: string) => {
		router.push(`/room/view/${id}`)
	}, [router])

	const RenderRow = React.useCallback(
		({ index, style, data }) => {
			const room = data[index] as RoomModel

			return (
				<div key={data[index].id} style={style} className={'px-10px'} onClick={() => selectRoom(room.id)} id={`select-room-${index}-id`}>
					<ModalMenuItem label={room.title} suffix={<p
						className={'text-gray-400 tracking-wider text-xl font-medium'}>{room.currentCount}/{room.total}</p>}/>
				</div>
			)
		},
		[selectRoom]
	)

	const createRoom = React.useCallback(() => {

	}, []);

	return (
		<Modal isOpen={true}
					 title={{ firstLine: isCreate ? 'Create' : 'View', secondLine: 'room' }}
					 description={'The property used to travel to other cities on the network and create or join multiplayer rooms in the HODLVERSE network.'}
					 size={Sizes.SMALLER}
					 mainIconName={'Airport'}>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`${styles.body} opacity-100 ${isCreate ? 'py-40px px-50px' : 'pt-20px pb-40px px-40px'} xs:py-40px xs:px-25px`}>
					<div className={`h-full flex flex-col ${isCreate && 'justify-center'}`}>
						{isCreate ? (
							<>
								<ModalMenuInputItem placeholder={'Room Name...'} value={roomName} onChange={setRoomName}/>
								<div className={'mt-40px flex items-center justify-between'}>
									<Button type={ButtonType.GHOST} size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG}
													onClick={() => router.push(airportPageLink)} id={'create-room-cancel-btn-id'}>Cancel</Button>
									<Button size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG} onClick={createRoom} id={'create-room-create-btn-id'}>Create</Button>
								</div>
							</>
						) : (
							<>
								<div className={`mt-20px ${styles.listWrapper}`}>
									<FixedSizeList
										outerElementType={(props) => <PerfectScrollbar {...props} options={{ suppressScrollX: true }}/>}
										height={408}
										itemData={roomList}
										itemCount={roomList.length}
										itemSize={screenWidth < 70 ? Sizes.SMALL : 86}
										width={'100%'}
									>
										{RenderRow}
									</FixedSizeList>
								</div>
								<div className={'mt-30px flex items-center justify-between'}>
									<Button type={ButtonType.GHOST} size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG}
													onClick={() => router.push(airportPageLink)} id={'view-room-back-btn-id'}>Back</Button>
									<Button size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG} onClick={() => router.push(createRoomPageLink)}  id={'view-room-create-btn-id'}>Create</Button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default CreateViewRoom
