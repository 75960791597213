/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import { FixedSizeList } from 'react-window'
import { ChainId, Currency, NATIVE, Token } from '@hodlvalley/sdk'
import CHAINLINK_TOKENS from '@sushiswap/chainlink-whitelist/dist/sushiswap-chainlink.whitelist.json'
import limitOrderPairList from '@sushiswap/limit-order-pair-list/dist/limit-order.pairlist.json'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Button, ButtonType } from '../index'
import Input from './Input'
import Balance from '../CurrencyBalance'
import TokenInRow from './TokenInRaw'
import NoResults from 'components/TokenDropdown/NoResults'

import { useDerivedLimitOrderInfo } from 'state/limit-order/hooks'
import { Field } from 'state/swap/actions'

import { useSortedTokensByQuery, filterTokens } from 'functions/filtering'
import { isAddress } from 'functions'
import { shortenStringAsAddress } from 'functions'
import { useTokenComparator } from './sorting'

import { useActiveWeb3React } from 'hooks'
import { useAllTokens, useSearchInactiveTokenLists } from 'hooks/Tokens'
import useDebounce from 'hooks/useDebounce'
import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

import { nativeBitcoin } from '../../crosschainswap/Bitcoin'

import { TokenModel } from 'types/TokenModel'

import styles from './tokenDropdown.module.scss'
import ManageButton from 'components/TokenDropdown/manageButton'

const areEqual = (first, second) => {
	if (first.length !== second.length) {
		return false
	}
	for (let i = 0; i < first.length; i++) {
		if (!second.includes(first[i])) {
			return false
		}
	}
	return true
}

interface SelectTokensProps {
	onManageTokens: () => void;
	onAdd: (token: TokenModel) => void;
	onSelect: (token: TokenModel) => void;
	hideTokens?: Currency[];
	isSecond?: boolean;
}

const SelectTokens: React.FC<SelectTokensProps> = ({ onAdd, onManageTokens, onSelect, hideTokens, isSecond = [] }) => {
	const windowSize = useWindowSize()
	const { chainId, library } = useActiveWeb3React()
	const router = useRouter()
	const allTokens = useAllTokens()
	const { currencies } = useDerivedLimitOrderInfo()
	const { i18n } = useLingui()

	const [currencyInputPanelError, setCurrencyInputPanelError] = useState<string>()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [invertSearchOrder] = useState<boolean>(false)
	const [address, setAddress] = useState('')
	const [shortAddress, setShortAddress] = useState(address)
	const [focusedAddressInput, setFocusedAddressInput] = useState(false)
	const [filteredTokenList, setFilteredTokenList] = useState([])
	const [allTokenList, setAllTokenList] = useState<{ [p: string]: Token }>({})
	const [extraCustomAssets, setExtraCustomAssets] = useState([])

	const debouncedQuery = useDebounce(searchQuery, 200)

	const isAddressSearch = useMemo(() => {
		return isAddress(debouncedQuery)
	}, [debouncedQuery])

	const tokenComparator = useTokenComparator(invertSearchOrder)

	const handleAdd = useCallback((e, token) => {
		e.stopPropagation()
		onAdd(token)
	}, [onAdd])

	const pairs = useMemo(() => {
		return (limitOrderPairList.pairs[chainId] || []).map(([token0, token1]) => [token0.address, token1.address])
	}, [chainId])

	const currencyList = useMemo(() => {
		if (pairs.length === 0) return []
		return pairs.reduce((acc, [token0, token1]) => {
			acc.push(token0)
			acc.push(token1)
			return acc
		}, [])
	}, [pairs])

	const filteredTokens: Token[] = useMemo(() => {
		return filterTokens(Object.values(allTokenList), debouncedQuery)
	}, [allTokenList, debouncedQuery])

	const sortedTokens: Token[] = useMemo(() => {
		return filteredTokens.sort(tokenComparator)
	}, [filteredTokens, tokenComparator])

	const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery)

	const ether = useMemo(() => chainId && ![ChainId.CELO].includes(chainId) && NATIVE[chainId], [chainId])

	useEffect(() => {
		const assets = []
		// @ts-ignore
		if (library?.provider?.isCoinWallet) {
			assets.push(nativeBitcoin[chainId])
		}
		setExtraCustomAssets(assets)
	}, [library, chainId])

	const filteredSortedTokensWithETH: Currency[] = useMemo(() => {
		const s = debouncedQuery.toLowerCase().trim()
		if (s === '' || s === 'e' || s === 'et' || s === 'eth') {
			return ether ? [ether, ...extraCustomAssets, ...filteredSortedTokens] : filteredSortedTokens
		}
		return filteredSortedTokens
	}, [debouncedQuery, ether, filteredSortedTokens, extraCustomAssets])

	const filteredInactiveTokens = useSearchInactiveTokenLists(
		filteredTokens.length === 0 || (debouncedQuery.length > 2 && !isAddressSearch) ? debouncedQuery : undefined
	)

	useEffect(() => {
		let tokenList = allTokens
		if (router.asPath.startsWith('/kashi/create')) {
			tokenList = Object.keys(allTokens).reduce((obj, key) => {
				if (CHAINLINK_TOKENS[chainId].find((address) => address === key)) obj[key] = allTokens[key]
				return obj
			}, {})
		}

		if (currencyList.length) {
			tokenList = Object.keys(allTokens).reduce((obj, key) => {
				if (currencyList.includes(key)) obj[key] = allTokens[key]
				return obj
			}, {})
		}
		setAllTokenList(tokenList)
	}, [allTokens, router, currencyList, chainId])

	useEffect(() => {
		if (
			pairs &&
			currencies[Field.INPUT] &&
			currencies[Field.OUTPUT] &&
			!pairs.find((el) =>
				areEqual(el, [currencies[Field.INPUT].wrapped.address, currencies[Field.OUTPUT].wrapped.address])
			)
		) {
			setCurrencyInputPanelError('Invalid pair')
		} else if (currencyInputPanelError === 'Invalid pair') {
			setCurrencyInputPanelError('')
		}
	}, [currencies, currencyInputPanelError, pairs])

	useEffect(() => {
		setFilteredTokenList([...filteredSortedTokensWithETH, ...filteredInactiveTokens].filter(x => !hideTokens.includes(x)))
	}, [filteredSortedTokensWithETH, filteredInactiveTokens, hideTokens])

	useEffect(() => {
		setShortAddress(shortenStringAsAddress(address))
		setSearchQuery(isAddress(address) || address)
	}, [address])

	const Row = useCallback(
		({ data, index, style }) => {
			const token = data[index]

			return (
				<div
					className={'flex items-end justify-between pb-8px xs:pb-19px border-b-3 xs:border-b-2 border-gray-260 mb-18px xs:mb-14px last:border-0 last:mb-0 w-full'}
					key={index}
					style={style}
					onClick={() => onSelect(token)}>
					<TokenInRow token={token} wrapClassname={'w-9/12'}/>
					<div className={'flex items-center justify-end w-3/12'}>
						<Balance currency={token}/>
					</div>
					{/* {existingTokenList.some(x => x.address === token.address) ?
              <p className={'font-medium text-15px text-right text-blue-400'}>{token.balance}</p>
              :
              <Button type={ButtonType.GHOST} size={Sizes.EXTRA_SMALL} onClick={(e) => handleAdd(e, token)}>
                <p className={'tracking-wider'}>ADD</p>
              </Button>
            } */}
				</div>
			)
		},
		[onSelect]
	)

	return (
		<div className={styles.formWrapper} id={`select-token-dropdown-${isSecond ? 'second' : 'first'}-id`}>
			<div className={styles.formSearchWrapper}>
				<Input value={focusedAddressInput ? address : shortAddress}
							 onChange={(val) => {
								 setAddress(val)
							 }}
							 id={`enter-token-contract-address-${isSecond ? 'second' : 'first'}-id`}
							 placeholder={focusedAddressInput ? '' : `${i18n._(t`Enter token contract address`)}...`}
							 onFocus={() => setFocusedAddressInput(true)}
							 onBlur={() => setFocusedAddressInput(false)} />
				{/*<Input value={address}*/}
				{/*       onChange={(val) => {*/}
				{/*         setAddress(val)*/}
				{/*       }}*/}
				{/*       placeholder={'Enter token contact address…'}*/}
				{/*       onFocus={() => setFocusedAddressInput(true)}*/}
				{/*       onBlur={() => setFocusedAddressInput(false)}/>*/}
			</div>
			<div className={`h-160px xs:h-140px px-35px mt-24px xs:mt-16px flex flex-col overflow-y-scroll xs:px-17px`}>
				{filteredTokenList.length ?
					<FixedSizeList
						width={'100%'}
						height={windowSize.width < responsiveSizes.xs ? 140 : 160}
						itemData={filteredTokenList}
						itemCount={filteredTokenList.length}
						itemSize={70}
					>
						{Row}
					</FixedSizeList>
					: <NoResults/>}
			</div>
			<ManageButton text={`${i18n._(t`Manage`)}`} handleClick={onManageTokens}/>
		</div>
	)
}

export default SelectTokens
