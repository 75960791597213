import {Currency, CurrencyAmount, Ether, JSBI, Pair, Percent, Token} from "@hodlvalley/sdk";
import {useTokenBalance, useTokenBalancesWithLoadingIndicator} from "../state/wallet/hooks";
import {useTotalSupply} from "./useTotalSupply";
import {useActiveWeb3React} from "./useActiveWeb3React";
import {unwrappedToken, currencyId} from "../functions";
import {useMemo} from "react";
import {toV2LiquidityToken, useTrackedTokenPairs} from "../state/user/hooks";
import {useV2Pair, useV2Pairs} from "./useV2Pairs";
import {getPairs, useLiquidityPositions, useMoneyPairs} from "services/graph";
import {useSelector} from "react-redux";
import {AppState} from "state/index";

export function usePositionCardInformation({ pair, showUnwrapped = false,}){
    const { account } = useActiveWeb3React()
    const stakedBalance = CurrencyAmount.fromRawAmount(pair.liquidityToken, '0')
    const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
    // if staked balance balance provided, add to standard liquidity amount
    const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

    const totalPoolTokens = useTotalSupply(pair.liquidityToken)

    const poolTokenPercentage =
        !!userPoolBalance &&
        !!totalPoolTokens &&
        JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
            ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
            : undefined


    const [token0Deposited, token1Deposited] =
        !!pair &&
        !!totalPoolTokens &&
        !!userPoolBalance &&
        // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
        JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
            ? [
                pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
                pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
            ]
            : [undefined, undefined]

    const currency0 = unwrappedToken(pair.token0)
    const currency1 = unwrappedToken(pair.token1)


    const urlEnd =`${currencyId(currency0)}/${currencyId(currency1)}`;

    const token0Rate = token0Deposited && token1Deposited? token0Deposited?.toSignificant(6)/token1Deposited?.toSignificant(6): 0
    const token1Rate = token0Deposited && token1Deposited? token0Deposited?.toSignificant(6)*token1Deposited?.toSignificant(6): 0

    return useMemo(()=>{
        return {
            urlEnd,
            poolTokenPercentage,
            currency0,
            currency1,
            token0Deposited,
            token1Deposited,
            token0Rate,
            token1Rate,
            userPoolBalance
        }
    }, [pair])
}
export function useGetPoolData({isMyPool}) {
    const { account } = useActiveWeb3React()
    const trackedTokenPairs = useTrackedTokenPairs()

    const tokenPairsWithLiquidityTokens = useMemo(
        () =>
            trackedTokenPairs.map((tokens) => {
                let liquidityToken;
                try {
                    liquidityToken = toV2LiquidityToken(tokens);
                } catch (e) {
                    liquidityToken = null;
                }

                return {
                    liquidityToken,
                    tokens,
                }}),
        [trackedTokenPairs]
    )

    const liquidityTokens = useMemo(
        () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
        [tokenPairsWithLiquidityTokens]
    )


    const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
        account ?? undefined,
        liquidityTokens
    )

    // fetch the reserves for all V2 pools in which the user has a balance
    const liquidityTokensWithBalances = useMemo(
        () => {
            if (isMyPool) {
                return tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
                    v2PairsBalances[liquidityToken?.address]?.greaterThan('0')
                )
            }
            return  tokenPairsWithLiquidityTokens

        },
        [tokenPairsWithLiquidityTokens, v2PairsBalances]
    )

    const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))

    const v2IsLoading =
        fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

    const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

    return useMemo(()=>{
        return {
            allV2PairsWithLiquidity,
            count: allV2PairsWithLiquidity.length ?? 0,
            v2IsLoading
        }
    }, [trackedTokenPairs])

}

export function useGetAllHodlPoolData(){

    const pairs = useMoneyPairs();
    return useMemo(()=>{
        if (Array.isArray(pairs)) {
            return pairs
        }
        return  []
    },[pairs])
}

export function useGetMyHodlPoolData () {
    const { account, chainId } = useActiveWeb3React()

    const customBookmarks = useSelector((state: AppState) => state.pool.bookmarkedPools);
    const customPairs = Array.isArray(customBookmarks?.[chainId]) ? customBookmarks?.[chainId]: []
    const userPositions = useLiquidityPositions({
        where: {
            liquidityTokenBalance_gt: 0,
            user: account?.toLowerCase()
        }
    })
    const pairAddresses = Array.isArray(userPositions)? userPositions.map(singleLiquidityPosition => singleLiquidityPosition.pair.id): []

    const uniquePairAddresses = [...new Set([...pairAddresses, ...customPairs])];

    const pairs = useMoneyPairs({
        where: {
            id_in: uniquePairAddresses
        }
    });
    return useMemo(()=>{
        if (Array.isArray(pairs)) {
            return pairs
        }
        return  []
    }, [pairs])
}
export function useGetPair (rawPair) {
    const { chainId } = useActiveWeb3React()
    const tokenA = new Token(chainId, rawPair.token0.id, 18, rawPair.token0.symbol, rawPair.token0.name);
    const tokenB = new Token(chainId, rawPair.token1.id, 18, rawPair.token1.symbol, rawPair.token1.name);

    const p = useV2Pair(tokenA, tokenB)
    return useMemo(()=>{
        if ( p[1]) {
            return p[1]
        }
        const currencyAmountA = CurrencyAmount.fromRawAmount(tokenA, '1');
        const currencyAmountB = CurrencyAmount.fromRawAmount(tokenB, '1');

        return new Pair(currencyAmountA, currencyAmountB);

    }, [p])
}
