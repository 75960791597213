import {ReactNode, useCallback, useState} from "react";
import {useRouter} from "next/router";

import {SearchAutocomplete, NetworkListDropdown, BackButton} from "components";

import styles from './analytics.module.scss'

interface LayoutProps {
  children: ReactNode;
  mobileTitle?: string;
  mobileTokenInfo?: ReactNode;
  searchOptions?: any[];
  onSelectSearchOption?: (item: any) => void;
}

const AnalyticsLayout: React.FC<LayoutProps> = ({ children,
                                                  mobileTitle,
                                                  mobileTokenInfo,
                                                  searchOptions,
                                                  onSelectSearchOption
                                                }) => {
  const router = useRouter();

  const handleBack = useCallback(() => {
    router.back();
  }, [router]);

  return (
    <div className={`flex justify-center pt-80px xs:pt-0`}>
      <div className={`w-1642px xs:w-full border-10 xs:border-0 border-white rounded-80 xs:rounded-none overflow-hidden`}>
        <div className={'flex items-start bg-white bg-opacity-80'}>
          <div className={'w-2/12 h-full h-10 xs:hidden'}>

          </div>
          <div className={'w-10/12 xs:w-full pl-26px xs:pl-0'}>
            <div className={'hidden xs:block'}>
              <h2 className={'text-xl uppercase font-medium text-gray-800 tracking-normal pt-25px pl-36px'}>{mobileTitle}</h2>
              <div className={'px-20px pt-5px'}>
                {mobileTokenInfo}
              </div>
            </div>
            <div className={'flex justify-between items-top pr-77px pt-50px xs:pt-9px xs:px-20px'}>
              <div className={'xs:hidden'}>
                <BackButton onClick={handleBack} />
              </div>
              <div className={'flex justify-end items-center xs:justify-between'}>
                <div className={`w-294px xs:w-94px xs:order-2 xs:ml-35px`}>
                  <SearchAutocomplete options={searchOptions} onChange={onSelectSearchOption} />
                </div>
                <div className={`w-294px xs:w-220px ml-25px xs:ml-0 rounded-full xs:order-1 ${styles.tokenDropdownWrapper}`}>
                  <NetworkListDropdown />
                </div>
              </div>
            </div>
            <div className={'pt-49px xs:pt-20px pl-6px pr-78px xs:px-0'}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnalyticsLayout
