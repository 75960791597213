import {useActiveWeb3React} from "hooks/index";
import {useCallback, useMemo} from "react";
import { ChainId} from "@hodlvalley/sdk";
import { CUSTOM_CHAINS } from "constants/networks";
import {showToast, ToastTypes} from "hooks/useToast";

export function useAddCustomNetwork() {
    const {library } = useActiveWeb3React()


    const addCustomChain = useCallback(async (chainId: ChainId): Promise<number>=>{
        if (library && library.provider.isMetaMask && library.provider.request) {
            if (chainId === ChainId.ROPSTEN || chainId === ChainId.MAINNET) {
                await library.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: CUSTOM_CHAINS[chainId].chainId}],
                })
                return chainId
            } else {
                await library.provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [CUSTOM_CHAINS[chainId]],
                })
                return chainId
            }

        } else {
            showToast('This is only supported on metamask', {
                type: ToastTypes.error
            })
        }
    }, [library])

    return useMemo(()=>{
        return {
            addCustomChain
        }
    }, [addCustomChain])
}
