import {useCallback, useMemo} from 'react'
import { CurrencyAmount } from '@hodlvalley/sdk'
import useSWR from 'swr'
import { useReserveContract as useGetReserveContract } from 'hooks/useContract'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import { MONEY } from 'constants/tokens'

export default function useReserveContract() {
	const contract = useGetReserveContract()
	const { account, chainId } = useActiveWeb3React()

	const moneyReserveBalance = useCallback(async () => {
		try {
			const tx = await contract?.moneyBalance()
			if (tx) {
				return account ? CurrencyAmount.fromRawAmount(MONEY[chainId], tx?.toString()) : null
			}
			return  null
		} catch (e) {
			console.log(e)
			return  null
		}
	}, [account, contract, chainId])

	const { data: moneyBalance } = useSWR(['moneyReserveBalance', account, chainId], moneyReserveBalance)

	return useMemo(()=>{
		return {
			moneyBalance
		}
	}, [moneyBalance])
}
