import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { BackButton, Modal, ModalMenuItem, MenuItemTypeModel } from 'components'

import { Sizes } from 'types/Sizes'
import { LinkItemModal } from 'types/LinkItemModal'

import useModals from 'state/modals/hooks'

import { useGetAllHodlPoolData, useGetMyHodlPoolData } from 'hooks/Pool'

import styles from './communityPool.module.scss'
import {NATIVE} from "@hodlvalley/sdk";
import {useActiveWeb3React} from "hooks/index";

interface PoolLinkItem extends LinkItemModal {
	count?: number;
}

const CommunityPoolModal: React.FC = () => {
	const router = useRouter()
	const { i18n } = useLingui()
	const { chainId } = useActiveWeb3React()
	const { closeModals, openModal } = useModals()

	const allPairs = useGetAllHodlPoolData()
	const myPairs = useGetMyHodlPoolData()

	const [showDetail, setShowDetail] = useState(false)

	const list: PoolLinkItem[] = useMemo(() => {
		return [
			{ label: `${i18n._(t`Add pool`)}`, type: MenuItemTypeModel.LINK, link: 	`/pool/add/${NATIVE[chainId].symbol}` },
			{ label: `${i18n._(t`View my pools`)}`, type: MenuItemTypeModel.LINK, link: '/pool/myview', count: myPairs.length },
			{ label: `${i18n._(t`View all pools`)}`, type: MenuItemTypeModel.LINK, link: '/pool/allview', count: allPairs.length },
			{ label: `${i18n._(t`Property details`)}`, type: MenuItemTypeModel.LOCAL_EVENT, link: 'PROPERTY_DETAILS' }
		]
	}, [myPairs.length, allPairs.length])

	const toggleDetails = useCallback(() => {
		setShowDetail(!showDetail)
	}, [showDetail])

	const selectModal = useCallback((item: LinkItemModal): void => {
		if (item.link) {
			if (MenuItemTypeModel.LINK === item.type) {
				closeModals()
			}
			if (item.type === MenuItemTypeModel.LINK) {
				router.push(`${item.link}`)
			}
			if (item.type === MenuItemTypeModel.LOCAL_EVENT && item.link === 'PROPERTY_DETAILS') {
				toggleDetails()
			}
		}
	}, [closeModals, openModal, toggleDetails])

	return (
		<Modal isOpen={true}
					 title={{ firstLine: `${i18n._(t`Community`)}`, secondLine: `${i18n._(t`Pool`)}` }}
					 description={`${i18n._(t`The property for all pools and adding liquidity in the HODLVERSE network`)}.`}
					 size={Sizes.SMALL}
					 mainIconName={'PoolOne'}
					 addTopSpace={showDetail}
					 addMobileTopSpace
					 customBackAction={showDetail ? toggleDetails : undefined}>
			<div className={`h-full relative xs:h-500px`}>
				<div
					className={`${styles.body} ${!showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'} flex flex-col justify-center`}>
					<div className={`h-full flex flex-col justify-center xs:justify-start`}>
						{list.map((item, index) => (
							<div className={'mb-20px xs:mb-15px'} key={index}>
								<ModalMenuItem label={item.label}
															 onSelect={() => selectModal(item)}
															 suffix={item.hasOwnProperty('count')
																 ? <p
																	 className={'ml-11px text-blue-400 tracking-normal font-medium text-xl'}>{item.count}</p>
																 : undefined}/>
							</div>
						))}
					</div>
				</div>
				<div className={`${styles.body} body-details ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <div className={'details-form'}>
            <p className='modal-description__title'>{i18n._(t`Name`)}</p>
            <p className='modal-description__value'>{i18n._(t`Community pool`)}</p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
            <p className='modal-description__title'>{i18n._(t`Description`)}</p>
            <p className={`modal-description__value min-h-118px xs:min-h-90px xs:text-sm xs:normal-case`}>
              {i18n._(t`The property for all pools and adding liquidity in the HODLVERSE network`)}.
            </p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
            <p className='modal-description__title'>{i18n._(t`Available for purchase`)}</p>
            <p className='modal-description__value'>{i18n._(t`No`)}</p>
          </div>
				</div>
			</div>
		</Modal>
	)
}

export default CommunityPoolModal
