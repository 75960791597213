import { ChainId } from '@hodlvalley/sdk'
import { RPC} from '../connectors'
const Arbitrum = '/images/networks/arbitrum-network.jpg'
const Avalanche = '/images/networks/avalanche-network.jpg'
const Bsc = '/images/networks/bsc-network.jpg'
const Fantom = '/images/networks/fantom-network.jpg'
const Goerli = '/images/networks/goerli-network.jpg'
const Harmony = '/images/networks/harmonyone-network.jpg'
const Heco = '/images/networks/heco-network.jpg'
const Kovan = '/images/networks/kovan-network.jpg'
const Mainnet = '/images/networks/mainnet-network.jpg'
const Matic = '/images/networks/matic-network.jpg'
const Moonbeam = '/images/networks/moonbeam-network.jpg'
const OKEx = '/images/networks/okex-network.jpg'
const Polygon = '/images/networks/polygon-network.jpg'
const Rinkeby = '/images/networks/rinkeby-network.jpg'
const Ropsten = '/images/networks/ropsten-network.jpg'
const xDai = '/images/networks/xdai-network.jpg'
const Celo = '/images/networks/celo-network.jpg'
export const NETWORK_ICON = {
  [ChainId.MAINNET]: Mainnet,
  [ChainId.ROPSTEN]: Ropsten,
  [ChainId.SEPOLIA]: Ropsten,
  [ChainId.RINKEBY]: Rinkeby,
  [ChainId.GÖRLI]: Goerli,
  [ChainId.KOVAN]: Kovan,
  [ChainId.FANTOM]: Fantom,
  [ChainId.FANTOM_TESTNET]: Fantom,
  [ChainId.BSC]: Bsc,
  [ChainId.BSC_TESTNET]: Bsc,
  [ChainId.MATIC]: Polygon,
  [ChainId.MATIC_TESTNET]: Matic,
  [ChainId.XDAI]: xDai,
  [ChainId.ARBITRUM]: Arbitrum,
  [ChainId.ARBITRUM_TESTNET]: Arbitrum,
  [ChainId.MOONBEAM_TESTNET]: Moonbeam,
  [ChainId.AVALANCHE]: Avalanche,
  [ChainId.AVALANCHE_TESTNET]: Avalanche,
  [ChainId.HECO]: Heco,
  [ChainId.HECO_TESTNET]: Heco,
  [ChainId.HARMONY]: Harmony,
  [ChainId.HARMONY_TESTNET]: Harmony,
  [ChainId.OKEX]: OKEx,
  [ChainId.OKEX_TESTNET]: OKEx,
  [ChainId.CELO]: Celo,
}

export const NETWORK_LABEL: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.SEPOLIA]: 'Sepolia',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.FANTOM]: 'Fantom',
  [ChainId.FANTOM_TESTNET]: 'Fantom Testnet',
  [ChainId.MATIC]: 'Polygon (Matic)',
  [ChainId.MATIC_TESTNET]: 'Matic Testnet',
  [ChainId.XDAI]: 'xDai',
  [ChainId.ARBITRUM]: 'Arbitrum',
  [ChainId.ARBITRUM_TESTNET]: 'Arbitrum Testnet',
  [ChainId.BSC]: 'BSC',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  [ChainId.MOONBEAM_TESTNET]: 'Moonbase',
  [ChainId.AVALANCHE]: 'Avalanche',
  [ChainId.AVALANCHE_TESTNET]: 'Fuji',
  [ChainId.HECO]: 'HECO',
  [ChainId.HECO_TESTNET]: 'HECO Testnet',
  [ChainId.HARMONY]: 'Harmony',
  [ChainId.HARMONY_TESTNET]: 'Harmony Testnet',
  [ChainId.OKEX]: 'OKEx',
  [ChainId.OKEX_TESTNET]: 'OKEx',
  [ChainId.CELO]: 'Celo',
}

export const NETWORK_NET: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: 'Mainnet',
  [ChainId.RINKEBY]: 'Testnet',
  [ChainId.SEPOLIA]: 'Testnet',
  [ChainId.ROPSTEN]: 'Testnet',
  [ChainId.GÖRLI]: 'Testnet',
  [ChainId.KOVAN]: 'Testnet',
  [ChainId.FANTOM]: 'Mainnet',
  [ChainId.FANTOM_TESTNET]: 'Testnet',
  [ChainId.MATIC]: 'Mainnet',
  [ChainId.MATIC_TESTNET]: 'Testnet',
  [ChainId.XDAI]: 'Mainnet',
  [ChainId.ARBITRUM]: 'Mainnet',
  [ChainId.ARBITRUM_TESTNET]: 'Testnet',
  [ChainId.BSC]: 'Mainnet',
  [ChainId.BSC_TESTNET]: 'Testnet',
  [ChainId.MOONBEAM_TESTNET]: 'Testnet',
  [ChainId.AVALANCHE]: 'Mainnet',
  [ChainId.AVALANCHE_TESTNET]: 'Testnet',
  [ChainId.HECO]: 'Mainnet',
  [ChainId.HECO_TESTNET]: 'Testnet',
  [ChainId.HARMONY]: 'Mainnet',
  [ChainId.HARMONY_TESTNET]: 'Testnet',
  [ChainId.OKEX]: 'Mainnet',
  [ChainId.OKEX_TESTNET]: 'Testnet',
  [ChainId.CELO]: 'Mainnet',
}

export const NETWORK_TYPE: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.RINKEBY]: 'Ethereum',
  [ChainId.SEPOLIA]: 'Ethereum',
  [ChainId.ROPSTEN]: 'Ethereum',
  [ChainId.GÖRLI]: 'Ethereum',
  [ChainId.KOVAN]: 'Ethereum',
  [ChainId.FANTOM]: 'Fantom',
  [ChainId.FANTOM_TESTNET]: 'Fantom',
  [ChainId.MATIC]: 'Matic',
  [ChainId.MATIC_TESTNET]: 'Matic',
  [ChainId.XDAI]: 'XDai',
  [ChainId.ARBITRUM]: 'Arbitrum',
  [ChainId.ARBITRUM_TESTNET]: 'Arbitrum',
  [ChainId.BSC]: 'Binance',
  [ChainId.BSC_TESTNET]: 'Binance'
}

export const CUSTOM_CHAINS: { [chainId: number]: any } = {
  [ChainId.ROPSTEN]: {
    chainId: `0x3`,
    chainName: 'Ropsten Test Network ',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: [RPC[ChainId.ROPSTEN]],
    blockExplorerUrls: ['https://ropsten.etherscan.io/']
  },
  [ChainId.AVALANCHE_TESTNET]: {
    chainId: `0xA869`,
    chainName: 'Avalanche Network Testnet',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: [RPC[ChainId.AVALANCHE_TESTNET]],
    blockExplorerUrls: ['https://explorer.avax-test.network/']
  },
  [ChainId.MATIC_TESTNET]: {
    chainId: `0x13881`,
    chainName: 'Polygon',
    nativeCurrency: {
      name: 'Polygon',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: [RPC[ChainId.MATIC_TESTNET]],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/']
  },
  [ChainId.FANTOM_TESTNET]: {
    chainId: `0xfa2`,
    chainName: 'Fantom testnet',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18
    },
    rpcUrls: [RPC[ChainId.FANTOM_TESTNET]],
    blockExplorerUrls: ['https://testnet.ftmscan.com/']
  },
  [ChainId.BSC_TESTNET]: {
    chainId: `0x61`,
    chainName: 'Smart Chain - Testnet',
    nativeCurrency: {
      name: 'Binance Smart Chain',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: [RPC[ChainId.BSC_TESTNET]],
    blockExplorerUrls: ['https://testnet.bscscan.com']
  },
}
